import React from "react";
import styled from "styled-components";
import { FaTwitter, FaGithub, FaLinkedin, FaDev } from "react-icons/fa";
import config from "../../config/website";
import theme from "../../config/theme";

const Footer = () => {
  return (
    <FooterStyles>
      <SocialStyles>
        <span>
          <a href={config.github} target="_blank" rel="noreferrer noopener" className="social__Link">
            <span className="text">Github</span>
            <FaGithub />
          </a>
        </span>
        <span>
          <a href={config.twitter} target="_blank" rel="noreferrer noopener" className="social__Link">
            <span className="text">Twitter</span>
            <FaTwitter />
          </a>
        </span>
        <span>
          <a href={config.linkedin} target="_blank" rel="noreferrer noopener" className="social__Link">
            <span className="text">LinkedIn</span>
            <FaLinkedin />
          </a>
        </span>
        <span>
          <a href={config.devto} target="_blank" rel="noreferrer noopener" className="social__Link">
            <span className="text">Devto</span>
            <FaDev />
          </a>
        </span>
      </SocialStyles>
      <CopyrightStyles>
        <p>{config.siteTitle} &copy; {new Date().getFullYear()}</p>
      </CopyrightStyles>
    </FooterStyles>
  )
}

// Component styles
const FooterStyles = styled.footer`
  background: linear-gradient(135deg, #FFCC33 0%, #E233FF 100%);
  border-radius: 10px;
  box-shadow: rgba(0,0,0,0.09) 0px 3px 12px;
  padding: 40px 20px;
`;

const SocialStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 40px;
  border-bottom: 1px solid #d3d3d37a;

  span {
    margin-right: 15px;
    font-size: 30px;
    
    .text {
      display: none;
    }
  }

  a {
    color: ${theme.colors.black};
  }
`;

const CopyrightStyles = styled.div`
  text-align: center;
  padding: 40px 0 20px;
`;

export default Footer;