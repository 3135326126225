import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import theme from "../../config/theme";

const Nav = () => {
  return (
    <NavStyles>
      <NavLiStyles><Link to="/">Home</Link></NavLiStyles>
      <NavLiStyles><Link to="/about">About</Link></NavLiStyles>
      <NavLiStyles><Link to="/contact">Contact</Link></NavLiStyles>
      <NavLiStyles><Link to="/uses">Uses</Link></NavLiStyles>
    </NavStyles>
  )
}

//Component styles
const NavStyles = styled.ul`
  padding: 0;
  margin: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  @media (max-width: ${theme.breakpoints.xs}) {
    padding-bottom: 15px;
  }
`;

const NavLiStyles = styled.li`
  font-size: 20px;
  margin-right: 12px;
  list-style-type: none;

  a {
    text-decoration: none;
    color: ${theme.colors.black};
  }
`;

export default Nav;