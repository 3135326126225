module.exports = {
  siteTitle: 'Creativehubspace', // Navigation and Site Title
  siteTitleAlt: 'Creativehubspace', // Alternative Site title for SEO
  siteTitleShort: 'creativehubspace', // short_name for manifest
  siteUrl: process.env.ROOT_URL || 'https://creativehubspace.com', // Domain of your site. No trailing slash!
  lang: 'en', // Language Tag on <html> element
  pathPrefix: '/',
  siteLogo: 'images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription:
      'Come check out how Creativehubspace can help grow your business and brand.',
  minibio: `
  About Creativehuspace
`,
  author: '', // Author for schemaORGJSONLD
  organization: 'Creativehubspace LLC',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@_dennisnm', // Twitter Username
  ogSiteName: '', // Facebook Site Name
  ogLanguage: 'en_US',

  // Manifest and Progress color
  themeColor: '',
  backgroundColor: '',

  // Social component
  twitter: 'https://twitter.com/_dennisnm/',
  twitterHandle: '@_dennisnm',
  github: 'https://github.com/creativehubspace/',
  linkedin: 'https://www.linkedin.com/in/mathengedennis/',
  devto: 'https://dev.to/creativehubspace',
  youtube: '',
  rss: '',
}