const colors = {
    bg_color: '#fff',
    gray: '#f4f4f4',
    light_gray: '#fafafa',
    darker_gray: '#6d5c5c0a',
    dark: '#212121',
    white: '#fff',
    black: '#333',
    blue: '#327cdc',
    border_gray: '#a1a1a0',
    border_green: '#10c732',
}

const theme = {
    colors,
    breakpoints: {
        xs: '420px',
        s: '600px',
        m: '900px',
        l: '1200px',
    },
    border_radius_default: '5px',
    box_shadow_default: '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)',
}


export default theme;