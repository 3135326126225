import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import logo from "../images/logo.png";
import Nav from "./Nav";
import theme from "../../config/theme";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          resize(width: 400) {
            src
          }
        }
      }
    }
 `);

  return (
    <header>
      <NavStyles>
        <h1>
          <Link to="/">
            <img
              src={data.logo.childImageSharp.resize.src}
              alt="Creativehubspace"
            />
          </Link>
        </h1>
        <Nav />
      </NavStyles>
    </header>
  )
}

const NavStyles = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
  @media (max-width: ${theme.breakpoints.xs}) {
    flex-direction: column;
  }
`;

export default Header;
