import { createGlobalStyle } from 'styled-components';
import theme from "../../../config/theme";

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  body {
    background-color: ${theme.colors.bg_color};
    max-width: 1300px;
    margin: 0 auto;
  }
  main {
    max-width: 850px;
    margin: auto;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
  h2 {
    font-weight: 700;
    padding: 1.125rem 0 1.125rem;
  }
  p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  
  ul {
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    li {
      list-style-type: none;
    }
  }
`;

export default GlobalStyles;