import React from "react";
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import GlobalStyles from './styles/GlobalStyles';
import favicon from '../images/favicon.png';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" sizes="32x32" href={favicon}  />
      </Helmet>
      <GlobalStyles />
      <Header />
      <div>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
